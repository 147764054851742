<template>
<!-- 订单详情 已退款 -->
  <div class="orderDetails">
    <van-nav-bar
      :title="this.$route.meta.title"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <ul class="orderWrapper">
      <li class="orderList">
        <span class="navTitle">商品名称：</span>
        <span class="navContent">【大流量】随身WiFi设备新款10000G/月</span>
      </li>
      <li class="orderList">
        <span class="navTitle">订单号：</span>
        <span class="navContent">51596165226126</span>
      </li>
      <li class="orderList">
        <span class="navTitle">下单时间：</span>
        <span class="navContent">2021-05-26 15:44:23</span>
      </li>
      <li class="orderList">
        <span class="navTitle">数量：</span>
        <span class="navContent">1</span>
      </li>
      <li class="orderList">
        <span class="navTitle">价格：</span>
        <span class="navContent price">¥199</span>
      </li>
    </ul>
    <ul class="orderWrapper">
      <li class="orderList">
        <span class="navTitle">联系人：</span>
        <span class="navContent">孙伟</span>
      </li>
      <li class="orderList">
        <span class="navTitle">联系电话：</span>
        <span class="navContent">15855222222</span>
      </li>
      <li class="orderList">
        <span class="navTitle">配送地址：</span>
        <span class="navContent">龙明小区8号楼1单元203</span>
      </li>
    </ul>
    <ul class="orderWrapper">
      <li class="orderList">
        <span class="navBottomTitle">订单状态：</span>
        <span class="navBottomContent">已退款</span>
      </li>
    </ul>
  </div>
</template>

<script>
import NavBar from 'components/navBar.vue'

export default {
  name: 'orderDetails',
  data () {
    return {
      
    }
  },
  methods: {
    onClickLeft () {
      this.$router.replace({
        name: 'order',
        params: {
          phone: this.$route.params.phone
        }
      })
    }
  },
  components: {
    NavBar
  }
}
</script>

<style lang="stylus" scoped>
  .orderDetails
    min-height 100%
    .orderWrapper
      margin 0 13px
      padding 20px 0
      border-bottom 1px solid #F7F7F7
      .orderList
        display flex
        margin-top 17px
        .navTitle
          flex 100px
          display inline-block
          font-size 13px
          font-weight 500
          color #999
        .navContent
          font-size 13px
          font-weight 500
          color #333
        .price
          color #FF5257
        .navBottomTitle
          flex 100px
          font-size 14px
          color #333
        .navBottomContent
          color #999
</style>